import './styles.css';

const Offline = () => {
  return (
    <>     
      <div className="container main-content">        
        <div className="row">          
          <h1 className="section-title site-unavailable"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Esta página da Web não está disponível</h1>
        </div>
      </div>
    </>
  );
}

export default Offline;